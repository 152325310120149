import React, { Component, memo } from "react";
import "./NewCasting.scss";
import { io } from "../../services/socket";
import {
  activityPolling,
  attendeeId,
  CheckValue,
  currentWorkoutTime,
  env,
  getAvailableUser,
  setCoach,
  setGym,
  setStaffInActivity,
  setUserInActivity,
  setWorkoutBuilder,
} from "../../services/CommonFunction";
import ApiURL from "../../services/ApiURL";
import axios from "../../axios";
import {
  playMemberStream,
  staffJoined,
  staffLeft,
  stopMemberStream,
  updateHeartRate,
  updateRanking,
} from "./SocketFun";
import Ongoing from "./Ongoing/Ongoing";
import WaitingRoom from "./WaitingRoom/WaitingRoom";
import { staticVar } from "../../services/Constants";
import {
  messageService,
  toasterSubscriber,
} from "../../services/subscriberService";
import { ErrorUI } from "../../components/UI/Modals/Modals";
import ZoomSDK from "./ZoomSDK/ZoomSDK";
import { CurrentUser } from "./ChildComponentUI/ChildComponentUI";

const abortController = new AbortController();
const signal = abortController.signal;

class NewCasting extends Component {
  constructor(props) {
    super(props);
    this.memberVideo = React.createRef();
    const ondemandData = {
      ...JSON.parse(localStorage.getItem("ondemandData")),
    };
    const workoutData = { ...JSON.parse(localStorage.getItem("workoutData")) };
    this.zoomRef = React.createRef();
    this.state = {
      socket: io(
        env("OnDemandSocket") + localStorage.getItem("accessToken"),
        {
          transports: ["websocket"],
          reconnection: true,
          reconnectionAttempts: Infinity,
          reconnectionDelay: 1000,
          reconnectionDelayMax: 5000,
          autoConnect: true,
        },
        { forceNew: true }
      ),
      totalSeconds: 0,
      required: {
        isHeartRateRequired: false,
        isLeaderBoardRequired: false,
      },
      staffsInActivity: {},
      coachData: {},
      gymData: {},
      allUsers: {},
      tagUsers: [],
      userActivityData: {},
      selfData: {},
      isInternetConnected: null,
      disconnectTime: 0,
      low_latency_mode: false,
      mute_participants_by_default: true,
      activityStatus: null,
      actionPerform: false,
      workoutData: { ...workoutData },
      ongoing: {
        ivsStatus: false,
        playBackURL: null,
        segmentDurations: [],
        started_at: undefined,
        start_datetime: undefined,
        total_activity_duration_in_seconds: 0,
      },
      ondemandData: { ...ondemandData },
      update: false,
      video_conference_data: null,
      themeColor: getComputedStyle(document.documentElement).getPropertyValue(
        "--logo-color"
      ),
    };
    
  }



  componentDidMount() {
    this.state.socket.on("connect", this.socketConnected);
    this.joinActivity(true);
    if (localStorage.getItem("firstTime")) {
      localStorage.removeItem("firstTime");
      ErrorUI(staticVar.textMsg, null, {
        close: () => {
          this.setState({ actionPerform: true });
        },
      });
    }

    // }
  }

  componentWillUnmount() {
    Object.keys(attendeeId).forEach((el) => {
      delete attendeeId[el];
    });
    this.state.socket.off("connect", this.socketConnected);
    this.callListners("off");
    this.state.socket.disconnect();
    abortController.abort();
    clearInterval(this.myInterval);
  }

  setSelfData = (data) => {
    const { userActivityData } = this.state;
    if (userActivityData.user_id === data.user_id) {
      this.setState({
        selfData: { ...data },
      });
    }
  };

  callListners = (status) => {
    // this.state.socket[status]('connect', this.socketConnected);
    this.state.socket[status]("disconnect", this.socketDisconnected);
    this.state.socket[status]("sync_tagged_videos", this.tagged_videos);
    this.state.socket[status]("video_tagged", (e) => this.startStream(e, 1));
    this.state.socket[status]("video_untagged", (e) => this.endStream(e, 1));
    this.state.socket[status]("self_heartbeat_sync", this.selfHeartbeat);
    this.state.socket[status]("activity_ended", (e) =>
      this.endActivity(e, "activity_ended")
    );
    this.state.socket[status]("web_preview_off", (e) =>
      this.endActivity(e, "web_preview_off")
    );

    this.state.socket[status]("heartbeat", (e) =>
      updateHeartRate(e, this.state.allUsers)
    );
    // this.state.socket[status]("heart_rate_in_waiting", e => updateHeartRate(e, this.state.allUsers,this.state.selfHeartbeat,this.state.userActivityData));
    this.state.socket[status]("heart_rate_in_waiting", (e) =>
      updateHeartRate(e, this.state.allUsers, this.setSelfData)
    );
    this.state.socket[status]("rankings", (e) =>
      updateRanking(e, this.state.allUsers)
    );
    this.state.socket[status]("new_user", this.addNewUser);
    this.state.socket[status]("user_left", (e) => this.removeUser(e));
    this.state.socket[status]("waiting_room_ended", (e) =>
      this.endActivity(e, "waiting_room_ended")
    );
    this.state.socket[status]("user_removed", (e) => this.forceRemove(e));
    this.state.socket[status]("activity_started", this.startActivity);
    this.state.socket[status]("live_stream_ended", this.setStream);
    this.state.socket[status]("live_stream_started", this.setStream);
    this.state.socket[status]("publish_stream_started", (e) =>
      playMemberStream(e, this.state.allUsers, this.state.userActivityData)
    );
    this.state.socket[status]("publish_stream_stopped", (e) => {
      if (!this.state.low_latency_mode) {
        stopMemberStream(e, this.state.allUsers, this.stateSet);
      }
    });
    this.state.socket[status]("staff_joined", (e) =>
      staffJoined(e, this.state.staffsInActivity)
    );
    this.state.socket[status]("staff_left", (e) =>
      staffLeft(e, this.state.staffsInActivity)
    );
    this.state.socket[status]("mute_unmute_participant", this.muteUser);

    // this.state.socket[status]("ondemand_activity_sync", this.end_activity);
  };

  muteUser = (data) => {
    const ondemandData = {
      ...JSON.parse(localStorage.getItem("ondemandData")),
    };
    if (
      CheckValue(this.zoom) &&
      (!CheckValue(data.user_activity_session_id) ||
        data.user_activity_session_id === ondemandData.user_activity_session_id)
    ) {
      this.zoom.stopAudio();
      toasterSubscriber.sendToasterData({
        showToaster: true,
        showImage: false,
        image: "",
        id: "mute",
        type: "copy",
        message: "You have been muted.",
      });
    }
  };
  socketConnected = () => {
    const ondemandData = {
      ...JSON.parse(localStorage.getItem("ondemandData")),
    };
    this.socketEmitHandler({
      activity_id: ondemandData.activity_id,
      user_activity_session_id: ondemandData.user_activity_session_id,
    });
  };

  tagged_videos = (data) => {
    if (data.tagged_user_session_ids && data.tagged_user_session_ids.length) {
      data.tagged_user_session_ids.forEach((el) => {
        Object.values(this.state.allUsers).forEach((element) => {
          if (
            el.user_activity_session_id === element.user_activity_session_id &&
            !element.me
          ) {
            this.startStream(
              { ...element, tagged_user_id: element.user_id },
              1
            );
          }
        });
      });
    }
  };

  socketEmitHandler = (data) => {
    data["isCoach"] = true;
    this.state.socket.emit("web_workout_sync_request", data, (obj) => {
      if (
        obj.user_status_in_activity === "ENDED" ||
        obj.web_preview === "false"
      ) {
        this.endActivity(undefined, "socketEmitHandler");
      } else {
        this.setState({
          isInternetConnected: true,
          disconnectTime: 0,
        });
        if (this.state.firstTaggedEmit) {
          this.syncSocketEmitHandler({
            user_activity_session_id:
              this.state.ondemandData.user_activity_session_id,
            web_socket_id: this.state.socket.id,
          });
        }
      }
    });
  };

  socketDisconnected = (data) => {
    this.setState({
      isInternetConnected: false,
      disconnectTime: this.state.totalSeconds,
    });
  };

  addNewUser = (data) => {
    const { allUsers } = this.state;
    if (allUsers.hasOwnProperty(data.user_id)) {
      if (allUsers[data.user_id].me) {
        this.endActivity(undefined, "addNewUser");
      } else {
        // allUsers[data.user_id]['color'] = '#808080';
        // allUsers[data.user_id]['opacity'] = 'unset';
        allUsers[data.user_id]["heartrate"] = 0;
        allUsers[data.user_id]["user_status_in_activity"] =
          this.checkActivityStatus;
        allUsers[data.user_id]["isVideoStarted"] = false;
      }
    } else {
      allUsers[data.user_id] = {
        ...data,
        // color: '#808080',
        maxHeartRate: Math.round(208 - 0.7 * data["age"]),
        calories_burnt: 0,
        // opacity: 'unset',
        heartrate: 0,
        muted: true,
        isVideoStarted: false,
        user_status_in_activity: this.checkActivityStatus,
      };
      const temp = Object.values(attendeeId).findIndex(
        (el) => el === data.user_id
      );
      if (temp >= 0) {
        const keys = Object.keys(attendeeId);
        allUsers[data.user_id].presentAttendeeId = keys[temp];
      }
    }
  };

  get checkActivityStatus() {
    const { activityStatus } = this.state;
    return !CheckValue(activityStatus) ||
      activityStatus === staticVar.waitingRoom
      ? staticVar.waiting
      : staticVar.roomStarted;
  }

  removeUser = (data, force = false) => {
    const { allUsers, tagUsers, activityStatus } = this.state;
    if (allUsers.hasOwnProperty(data.user_id)) {
      if (activityStatus === staticVar.waitingRoom || force) {
        if (allUsers[data.user_id].me) {
          this.endActivity(undefined, "removeUser");
        } else {
          const index = tagUsers.findIndex((el) => el.user_id === data.user_id);
          if (index > -1) {
            tagUsers.splice(index, 1);
          }
          delete attendeeId[allUsers[data.user_id].presentAttendeeId];
          delete allUsers[data.user_id];
        }
      } else {
        if (allUsers[data.user_id].me) {
          this.endActivity(undefined, "removeUser");
        } else {
          // allUsers[data.user_id]['color'] = '#808080';
          // allUsers[data.user_id]['opacity'] = '0.6';
          allUsers[data.user_id]["max_heart_rate_percent"] = 0;
          allUsers[data.user_id]["heartrate"] = 0;
          allUsers[data.user_id]["user_status_in_activity"] = "ENDED";
          allUsers[data.user_id]["stream_icon"] = false;
          this.endStream(allUsers[data.user_id], true);
        }
      }
    }
  };

  forceRemove = (data) => {
    const { allUsers } = this.state;
    if (allUsers[data.user_id].me) {
      this.endActivity(data, "user_removed");
    } else {
      this.removeUser(data, true);
    }
  };

  endActivity = async (data, status = true) => {
    localStorage.removeItem("workoutData");
    localStorage.removeItem("accessToken");
    if (this.memberVideos) {
      await this.memberVideos.stopStream();
    }
    if (status) {
      const obj = {
        user_id: this.state.userId,
        activity_id: this.state.userActivityData.activity_id,
        user_activity_session_id:
          this.state.userActivityData.user_activity_session_id,
      };
      await this.state.socket.emit("web_preview_off", obj, (data) => {});
    }
    this.setState((prevState) => ({
      ongoing: {
        ...prevState.ongoing,
        ivsStatus: false,
        playBackURL: null,
      },
    }));
    setTimeout(() => {
      this.props.history.push("/Home");
    }, 500);
  };

  stopStreaming = async (id = "videoElement") => {
    var videoElem = document.getElementById(id);
    const stream = videoElem.srcObject;
    if (stream === null) {
      return;
    }
    const tracks = stream.getTracks();
    videoElem.srcObject = null;
    await tracks.forEach(function (track) {
      track.stop();
    });
    return;
  };

  startActivity = async (data) => {
    const { allUsers } = this.state;
    await Object.values(allUsers).forEach((el) => {
      if (el.isVideoStarted) {
        this.zoom.stopAttendeeVideo(el.userId,true);
      }
      if (!this.state.low_latency_mode) {
        delete el["tileId"];
        el.stream_icon = false;
      }
    });
    this.setState((prevState) => ({
      // allUsers: allUsers,
      activityStatus: staticVar.roomStarted,
      totalSeconds: 0,
      ongoing: {
        ...prevState.ongoing,
        started_at: data.started_at
          ? data.started_at
          : new Date().toISOString(),
      },
    }));
  };

  startStream = (data, status) => {
    const { allUsers, tagUsers, userActivityData, socket } = this.state;
    let userId = data.tagged_user_id;
    if (allUsers[userId] && allUsers[userId].me) {
      return;
    }
    if (!status) {
      userId = data.user_id;
      if (allUsers[userId]) {
        allUsers[userId].showStream = true;
        this.setState({
          allUsers: allUsers,
        });
      }
      const tagData = {
        user_id: userActivityData.user_id,
        user_activity_session_id: userActivityData.user_activity_session_id,
        activity_id: data.activity_id,
        tagged_user_id: data.user_id,
        tagged_user_activity_session_id: data.user_activity_session_id,
      };
      socket.emit("video_tagged", tagData, (obj) => {});
    }
    if (allUsers.hasOwnProperty(userId)) {
      allUsers[userId].showStream = true;
    }
    const temp = tagUsers.findIndex((el) => el.user_id === userId);
    if (temp < 0) {
      tagUsers.push({ ...allUsers[userId] });
    } else {
      tagUsers[temp] = { ...allUsers[userId] };
    }
  };

  update = () => this.setState((prevState) => ({ update: !prevState.update }));

  endStream = (data, status) => {
    const {
      allUsers,
      tagUsers,
      userActivityData,
      socket,
      activityStatus,
      low_latency_mode,
    } = this.state;
    let userId = data.tagged_user_id || data.user_id;
    if (allUsers.hasOwnProperty(userId) && allUsers[userId].me) {
      return;
    }
    if (allUsers.hasOwnProperty(userId)) {
      if (activityStatus === staticVar.roomStarted) {
        if (low_latency_mode) {
        } else {
          if (allUsers[userId].stream) {
            allUsers[userId].stream.stop(
              data.tagged_user_activity_session_id ||
                data.user_activity_session_id
            );
            delete allUsers[userId].stream;
          }
        }
        allUsers[userId].isVideoStarted = false;
      }
      allUsers[userId].showStream = false;
    }

    const index = tagUsers.findIndex((el) => el.user_id === userId);
    if (index > -1) {
      tagUsers.splice(index, 1);
      this.setState({
        tagUsers: tagUsers,
      });
    }

    if (!status) {
      const tagData = {
        user_id: userActivityData.user_id,
        user_activity_session_id: userActivityData.user_activity_session_id,
        activity_id: data.activity_id,
        tagged_user_id: data.user_id,
        tagged_user_activity_session_id: data.user_activity_session_id,
      };
      socket.emit("video_untagged", tagData, (obj) => {});
    }
  };

  selfHeartbeat = (data) => {
    this.setState({
      selfData: {
        maxHeartRatePercent: data.max_of_max_heart_rate_percent,
        maxheartrate: data.max_heart_rate,
        heartrate: data.current_heart_rate,
        calories_burnt: data.calories_burnt,
        max_heart_rate_percent: data.max_heart_rate_percent,
      },
    });
  };

  setStream = (data) => {
    if (CheckValue(data.playbackUrl)) {
      this.setState((prevState) => ({
        ongoing: {
          ...prevState.ongoing,
          ivsStatus: true,
          playBackURL: data.playbackUrl,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ongoing: {
          ...prevState.ongoing,
          ivsStatus: false,
        },
      }));

      // window.location.reload();
      // this.setState({
      //     ivsStatus: true,
      //     playBackURL: data.playbackUrl
      // });
    }
  };

  // End Socket

  joinActivity = (first = false) => {
    const { workoutData, staffsInActivity, ongoing, ondemandData, socket } =
      this.state;
    axios
      .post(ApiURL._joinActivityV3, {
        activity_id: workoutData.activity_id,
        video_platform: "ZOOM",
        is_request_from_casting: true,
      })
      .then(async (response) => {
        const data = response.data.data;
        if (data.activity_status === staticVar.ended) {
          this.clear();
          return;
        }
        await setStaffInActivity(data, staffsInActivity);
        if (CheckValue(data.playback_url) && CheckValue(data.stream_key_arn)) {
          ongoing.ivsStatus = true;
          ongoing.playBackURL = data.playback_url;
        }
        if (data.workout_builder && data.workout_builder.length) {
          await setWorkoutBuilder(data, currentWorkoutTime(data.started_at));
          ongoing.segmentDurations = [...data.workout_builder];
        }
        this.setState((prevState) => ({
          coachData: setCoach(data),
          showSeekBar: true,
          allUsers: { ...prevState.allUsers, ...setUserInActivity(data) },
          ongoing: {
            ...prevState.ongoing,
            started_at: data.started_at,
            start_datetime: data.start_datetime,
            total_activity_duration_in_seconds:
              data.total_activity_duration_in_seconds,
          },
          required: {
            isHeartRateRequired: data.heartrate_required,
            isLeaderBoardRequired: data.leaderboard_type,
          },
          userActivityData: { ...data.user_activity_data },
          gymData: setGym(data),
          low_latency_mode: data.low_latency_mode,
          mute_participants_by_default: data.mute_participants_by_default,
          userType: localStorage.getItem("userType") || "everyone",
          activityStatus: data.activity_status,
          video_conference_data: data.video_conference_object,
          totalSeconds: this.getTime(data.activity_status, {
            started_at: data.started_at,
            start_datetime: data.start_datetime,
          }),
        }));
        if (first) {
          this.startTimer();
          this.callListners("on");
        }
        if (!this.state.firstTaggedEmit) {
          setTimeout(() => {
            this.syncSocketEmitHandler({
              user_activity_session_id: ondemandData.user_activity_session_id,
              web_socket_id: socket.id,
            });
            this.setState({
              firstTaggedEmit: true,
            });
          }, 1500);
        }
      })
      .catch(async (error) => {
        const obj = {
          user_id: this.state.userId,
          activity_id: this.state.userActivityData.activity_id,
          user_activity_session_id:
            this.state.userActivityData.user_activity_session_id,
        };
        await this.state.socket.emit("web_preview_off", obj, (data) => {});
        setTimeout(() => {
          this.state.socket.disconnect();
        }, 200);
        // const obj = {
        //     'user_id': this.ondemandData.userId,
        //     'activity_id': this.state.workoutData.activity_id,
        //     'user_activity_session_id': this.state.ondemandData.user_activity_session_id
        // }
        // this.state.socket.emit("web_preview_off", obj, (data) => {});
        this.clear();
      });
  };

  clear = () => {
    localStorage.removeItem("workoutData");
    localStorage.removeItem("userType");
    localStorage.removeItem("accessToken");
    this.props.history.push("/Home");
  };

  syncSocketEmitHandler = (data) => {
    this.state.socket.emit("sync_tagged_videos", data, (obj) => {});
  };

  syncActivity = () => {
    const { allUsers, activityStatus, ongoing, workoutData } = this.state;
    let data = {
      user_ids: Object.keys(allUsers),
      activity_id: workoutData.activity_id,
    };
    messageService.showLoader = false;
    axios.post(ApiURL._syncActivity, data,{ signal }).then((res) => {
      if (!signal.aborted) {
        const { activityInfo, newUsersJoined, usersThatLeft } = res.data.data;
  
        if (
          CheckValue(activityInfo.started_at) &&
          ongoing.started_at !== activityInfo.started_at
        ) {
          ongoing.started_at = activityInfo.started_at;
        }
        if (CheckValue(allUsers)) {
          if (newUsersJoined.length) {
            newUsersJoined.forEach((el) => {
              this.addNewUser(el);
            });
          }
          if (usersThatLeft.length) {
            usersThatLeft.forEach((el) => {
              this.removeUser(el);
            });
          }
        }
        if (
          CheckValue(activityStatus) &&
          activityInfo.activity_status !== activityStatus
        ) {
          this.setState({ activityStatus: activityInfo.activity_status });
          if (activityInfo.activity_status === staticVar.roomStarted) {
            this.startActivity(activityInfo);
          }
        }
      }
    }).catch(err => {
      if (!signal.aborted) {
        console.error(err);
      }
    });
  };

  startTimer = () => {
    this.myInterval = setInterval(() => {
      if (this.state.totalSeconds !== 0) {
        if (!(this.state.totalSeconds % 8)) {
          activityPolling(this.state.ondemandData, this.state.socket);
        }
        if (!(this.state.totalSeconds % 30)) {
          this.syncActivity();
        }
      }
      this.setState((prevState) => ({
        totalSeconds: this.getTime(prevState.activityStatus, prevState.ongoing),
      }));
    }, 1000);
  };

  getTime = (activityStatus, ongoing) => {
    let totalSeconds = 0;
    if (activityStatus === staticVar.roomStarted) {
      totalSeconds = currentWorkoutTime(ongoing.started_at, 1);
    } else {
      totalSeconds = currentWorkoutTime(ongoing.start_datetime, 0);
    }
    return totalSeconds;
  };

  stateSet = (obj) => {
    this.setState(obj);
  };

  userPin = (id) => {
    const { allUsers, low_latency_mode } = this.state;
    if (allUsers.hasOwnProperty(id) && !allUsers[id].me) {
      if (allUsers[id].showStream) {
        this.endStream(allUsers[id], 0);
      } else {
        const { allUsersArr } = getAvailableUser(allUsers, "all");
        const length = allUsersArr.filter(
          (el) => !el.me && el.showStream
        ).length;
        if (low_latency_mode && length < 3) {
          this.startStream(allUsers[id], 0);
        } else if (!low_latency_mode && length < 3) {
          this.startStream(allUsers[id], 0);
        } else {
          ErrorUI("You can pin up to 3 people.");
        }
      }
    }
  };

  get memberVideos() {
    return this.memberVideo.current;
  }

  get memberVideosData() {
    if (this.memberVideos) {
      return this.memberVideos.getMemberVideoData;
    } else {
      return {
        constraints: {
          audio: { autoplay: true, deviceId: "" },
          video: { width: 1280, height: 720, deviceId: "" },
        },
        permissions: false,
        isCameraEnabled: false,
        videoId: "memberVideo",
        webRTCAdaptor: null,
      };
    }
  }

  get zoom() {
    return this.zoomRef.current;
  }

  get zoomData() {
    if (this.zoom) {
      return this.zoom.getZoomData;
    } else {
      return {
        sessionStatus: 0,
        OvCameraId: undefined,
        OvAudioId: undefined,
        activeSpeaker: null,
        videoTiles: {},
        audioInputDevices: [],
        audioOutputDevices: [],
        videoInputDevices: [],
        isCameraEnabled: false,
        isAudioEnabled: false,
        isLocalStreamStarted: false,
        constraints: {
          audio: { autoplay: true, deviceId: "" },
          video: { width: 1280, height: 720, deviceId: "" },
        },
        width: 217,
        height: 453,
      };
    }
  }

  render() {
    const {
      activityStatus,
      low_latency_mode,
      socket,
      allUsers,
      coachData,
      stateSet,
      id,
      userActivityData,
      video_conference_data,
      mute_participants_by_default,
    } = this.state;
    const canvas = <CanvasUI />;
    const temp = {
      ...this.state,
      userPin: this.userPin,
      startStream: this.startStream,
      stateSet: this.stateSet,
      endStream: this.endStream,
      webPreviewOff: () => this.endActivity(undefined, "web_preview_off"),
      canvas,
    };
    return (
      <React.Fragment>
        {activityStatus === staticVar.waitingRoom || low_latency_mode ? (
          <ZoomSDK
            socket={socket}
            ref={this.zoomRef}
            allUsers={allUsers}
            coachData={coachData}
            clas="coachVideo"
            stateSet={stateSet}
            isOngoingRoom={activityStatus === staticVar.roomStarted}
            id={id}
            // zoomUpdate={obj => { this.setState(obj) }}
            userActivityData={userActivityData}
            video_conference_data={video_conference_data}
            streamControlsHandler={this.streamControlsHandler}
            activity_status={activityStatus}
          />
        ) : null}
        {this.zoomData && this.zoomData.activeSpeaker ? (
          <CurrentUser
            speaker={this.zoomData.activeSpeaker}
            allUsers={allUsers}
          />
        ) : null}
        {activityStatus === staticVar.waitingRoom ? (
          <WaitingRoom zoom={this.zoom} zoomData={this.zoomData} {...temp} />
        ) : null}
        {activityStatus === staticVar.roomStarted ? (
          <Ongoing
            memberVideo={this.memberVideo}
            memberVideos={this.memberVideos}
            memberVideosData={this.memberVideosData}
            low_latency_mode={low_latency_mode}
            zoom={this.zoom}
            zoomData={this.zoomData}
            mute_participants_by_default={mute_participants_by_default}
            {...temp}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default NewCasting;

const CanvasUI = memo(() => (
  <canvas
    id="coach_canvas_view"
    className={`${"coachVideo"}`}
    width="1920"
    height="1080"
  ></canvas>
));
