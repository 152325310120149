import React, { Component } from "react";
import "./StreamMember.scss";
import {
  CheckValue,
  env,
  getMediaPermission,
} from "../../../services/CommonFunction";
import { ErrorUI } from "../../../components/UI/Modals/Modals";
import { messageService } from "../../../services/subscriberService";
import axios from "../../../axios";
import ApiURL from "../../../services/ApiURL";
import { WebRTCAdaptor } from "@antmedia/webrtc_adaptor";

class StreamMember extends Component {
  state = {
    constraints: { video: { width: 1280, height: 720, deviceId: "" } },
    isCameraEnabled: false,
    isStreamStarted: false,
    videoId: "memberVideo",
    webRTCAdaptor: null,
  };

  get getStreamData() {
    return this.state;
  }

  componentDidMount() {
    this.checkStorage();
  }

  checkStorage = () => {
    console.log(localStorage.getItem("isCameraEnable"));
    if (localStorage.getItem("isCameraEnable")) {
      this.checkPermission();
    }
  };
  componentWillUnmount() {
    this.stopStream();
    this.closeCamera();
  }

  get getMemberVideoData() {
    return { ...this.state };
  }

  startStream = () => {
    const { userActivityData } = this.props;
    axios
      .post(ApiURL._beforeStartMyVideo, {
        user_activity_session_id: userActivityData.user_activity_session_id,
      })
      .then(async (res) => {
        // this.checkPermission(props, streamStarted);
        this.startLocalStream();
      })
      .catch((err) => {
        // ErrorUI(err.response.data);
        // this.checkPermission(props, streamStarted);
        this.startLocalStream();
      });
  };

  checkPermission = () => {
    getMediaPermission(
      this.state.constraints,
      this.state.videoId,
      0,
      false
    ).then((res) => {
      if (res.status) {
        this.startStream();
      } else {
        ErrorUI(
          "Kindly allow access to your camera and microphone in your settings and try again.",
          null,
          {
            close: this.checkPermission,
          }
        );
      }
    });
  };

  startLocalStream = () => {
    messageService.background = true;
    messageService.sendMessage(true);
    const { userActivityData } = this.props;
    const streamStarted = this.streamStartedStopped;
    const userId = userActivityData.user_activity_session_id;
    if (CheckValue(this.state.webRTCAdaptor)) {
      this.state.webRTCAdaptor.publish(userId);
      return;
    }

    var sdpConstraints = {
      OfferToReceiveAudio: false,
      OfferToReceiveVideo: true,
    };

    var mediaConstraints = {
      // video: true,
      video: {
        width: { ideal: 640 },  // 720p resolution (adjust to 854 for 480p)
        height: { ideal: 360 },  // 720p (adjust to 480 for 480p)
        frameRate: { ideal: 15 }, // 24 FPS (adjust lower if needed)
    },
      audio: false,
    };

    let webRTCAdaptor = new WebRTCAdaptor({
      websocket_url: env("AntMediaUrl"),
      mediaConstraints: mediaConstraints,
      peerconnection_config: null,
      sdp_constraints: sdpConstraints,
      localVideoId: this.state.videoId,
      isPlayMode: true,
      // bandwidth: 1200,
      bandwidth: {
        video: 1000,  // 1 Mbps for video (reduce for lower usage, e.g., 700 Kbps for 480p)
        audio: 64000  // 64 Kbps for audio (can adjust to 96 Kbps if higher quality needed)
    },
      debug: false,
      dataChannelEnabled: false,
      callback: function (info) {
        console.log(info);
        if (info === "initialized") {
          let timeoutTime = 1;
          setTimeout(() => {
            webRTCAdaptor.publish(userId);
          }, 10000 * timeoutTime);
          //called by JavaScript SDK when WebSocket is connected.
        } else if (info === "joinedTheRoom") {
          //called when this client is joined the room
          //obj contains streamId field which this client can use to publish to the room.
          //obj contains also the active streams in the room so that you can play them directly.
          //In order to get the streams in the room periodically call `getRoominfo`
        } else if (info === "newStreamAvailable") {
          //called when client is ready to play WebRTC stream.
        } else if (info === "publish_started") {
          streamStarted(true);
          //called when stream publishing is started for this client
        } else if (info === "publish_finished") {
          streamStarted(false);
          //called when stream publishing has finished for this client
        } else if (info === "leavedFromRoom") {
          //called when this client is leaved from the room
        } else if (info === "closed") {
          //called when websocket connection is closed
        } else if (info === "play_finished") {
          //called when a stream has finished playing
        } else if (info === "streamInformation") {
          //called when a stream information is received from the server.
          //This is the response of `getStreamInfo` method
        } else if (info === "roomInformation") {
          //Called by response of `getRoomInfo` when a room information is received from the server.
          //It contains the array of the streams in the room.
        } else if (info === "data_channel_opened") {
          // console.log('data_channel_opened');
          //called when data channel is opened
        } else if (info === "data_channel_closed") {
          // console.log('data_channel_closed');
          // streamStarted(null);
          // called when data channel is closed
        } else if (info === "data_received") {
          //called when data is received through data channel
        }
      },
      callbackError: function (error) {
        //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError
        console.info("error callback: " + error);
        if (error === "already_publishing") {
          streamStarted(null);
        }
        // streamStarted(false);
        messageService.background = false;
        messageService.sendMessage(false);
      },
    });
    this.setState({
      webRTCAdaptor: webRTCAdaptor,
    });
  };

  streamStartedStopped = (status) => {
    if (!CheckValue(status)) {
      if (!this.state.isStreamStarted && this.state.webRTCAdaptor) {
        this.stopStream();
        messageService.background = true;
        messageService.sendMessage(true);

        setTimeout(() => {
          messageService.background = false;
          messageService.sendMessage(false);
          this.startLocalStream();
        }, 500);
      } else {
        messageService.background = false;
        messageService.sendMessage(false);
      }
    } else if (this.state.isStreamStarted !== status) {
      this.setState({ isStreamStarted: status });
      const obj = {
        ...this.props.userActivityData,
      };
      if (this.props.pushSocket) {
        this.props.socket.emit(
          status ? "publish_stream_started" : "publish_stream_stopped",
          obj,
          (data) => {}
        );
      }
      messageService.background = false;
      messageService.sendMessage(false);
      let temp = {
        isCameraEnabled: status,
      };
      if (!status) {
        this.closeCamera();
        temp["webRTCAdaptor"] = null;
        console.log("call-streamStartedStopped");
        localStorage.removeItem("isCameraEnable");
      } else {
        localStorage.setItem("isCameraEnable", "true");
      }
      this.setState({ ...temp });
    }
  };

  closeCamera = () => {
    const videoElem = document.getElementById(this.state.videoId);
    if (CheckValue(videoElem)) {
      const stream = videoElem.srcObject;
      if (CheckValue(stream)) {
        stream.getAudioTracks().forEach(function (track) {
          track.stop();
        });
        stream.getVideoTracks().forEach(function (track) {
          //in case... :)
          track.stop();
        });
        videoElem.srcObject = null;
      }
    }
  };

  stopStream = () => {
    if (CheckValue(this.state.webRTCAdaptor)) {
      this.state.webRTCAdaptor.stop(
        this.props.userActivityData.user_activity_session_id
      );
    }
  };

  // onOffCamera = (status) => {
  //     if (status) {
  //         this.state.webRTCAdaptor.turnOnLocalCamera();
  //     } else {
  //         this.state.webRTCAdaptor.turnOffLocalCamera();
  //     }
  //     this.setState({
  //         // webRTCAdaptor: null,
  //         isCameraEnabled: status
  //     });
  // }

  startStopStream = () => {
    if (this.state.isCameraEnabled) {
      this.stopStream();
    } else {
      this.checkPermission();
      // this.startStream(this.props.userActivityData, this.streamStartedStopped);
    }
  };

  render() {
    const { videoId } = this.state;
    return (
      <React.Fragment>
        <video className={`member_video1`} id={videoId} autoPlay muted></video>
      </React.Fragment>
    );
  }
}

export default StreamMember;
